body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.header {
	background-color: #3c5c87;
	color: white;
}

.searchHeader {
	background-color: #3c5c87;
	color: white;
}
.headerOptions {
	color: white;
}

.headerOptions:hover {
	color: white;
}
.tableCard {
	overflow-y: auto;
	height: 80vh;
}
.thead-dark {
	font-size: small;
}

.selectedRow {
	background-color: rgb(197, 255, 191);
}
.selectedRowStriped {
	background-color: rgb(181, 234, 175) !important;
}
.noOverflow {
	overflow: hidden;
}
tbody {
	font-size: small;
}
